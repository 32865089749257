<template>
    <div v-if="me" class="edit-style">
        <StackRouterHeaderBar
            :class="{ 'bottom-border': showHeaderTitle }"
            :title="$translate('STYLE')"
            :show-title="showHeaderTitle"
        />
        <main class="main" @scroll="onScroll">
            <div class="page-header m-b-40">
                <div class="title" v-html="$translate('STYLE')" />
            </div>
            <Slider
                ref="ageSlider"
                :title="'나이'"
                :range="ageValue"
                :unit="'세'"
                :slider-options="ageSliderOptions"
                @change="updateStyleChanged"
            />
            <Slider
                ref="heightSlider"
                :title="'키'"
                :range="heightValue"
                :unit="'cm'"
                :slider-options="heightSliderOptions"
                @change="updateStyleChanged"
            />
            <div class="types">
                <div @click="editType(type.key)" class="item" :key="type.key" v-for="type in types">
                    <div class="key" v-html="$translate(type.title)" />
                    <div class="value" v-html="$translate(type.value)" />
                </div>
            </div>
            <hr />
            <EditIntro :childOf="'style'" v-model="style.intro" />
        </main>
        <BottomButton :disabled="!btnAble" @click="save" />
    </div>
</template>

<script>
import EditIntro from '../my-page/components/EditIntro'
import userService from '@/services/user'

export default {
    name: 'EditStylePage',
    components: { EditIntro },
    data: () => ({
        ageValue: [],
        ageSliderOptions: {
            min: 20,
            max: 100,
            tooltip: 'none',
        },
        heightValue: [],
        heightSliderOptions: {
            min: 140,
            max: 199,
            tooltip: 'none',
        },
        types: [],
        styleChanged: false,
        showHeaderTitle: false,
        disabled: {
            region: null,
            income: null,
            marry: null,
            child: null,
        },
        btnAble: false,
        managerCallAlert: true,
    }),
    watch: {
        style: {
            deep: true,
            handler: 'updateStyleChanged',
        },
        disabled: {
            deep: true,
            handler(newVal) {
                this.btnAble = newVal.region && newVal.marry && newVal.child && newVal.income
            },
        },
    },
    created() {
        this.init()
    },
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        style() {
            return this.me.style || {}
        },
    },
    methods: {
        initTypes() {
            this.types = ['region', 'income', 'marry', 'child'].map(key => ({
                key,
                title: `${key.toUpperCase()}_TYPE`,
                value: this.typeTitle(key),
            }))
        },
        init() {
            if (!this.me) return

            if (this.style) {
                this.managerCallAlert = false
                this.disabled = {
                    region: this.style.region_type,
                    income: this.style.income_type,
                    marry: this.style.marry_type,
                    child: this.style.child_type,
                }
            }

            if (this.managerCallAlert) {
                this.$modal.basic({
                    body: '매니저가 배정되었습니다.<br>매니저가 안내 전화를 드릴 예정입니다. (휴일 제외)',
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
            }

            this.ageValue = [
                this.style.min_age || this.ageSliderOptions.min,
                this.style.max_age || this.ageSliderOptions.max,
            ]
            this.heightValue = [
                this.style.min_height || this.heightSliderOptions.min,
                this.style.max_height || this.heightSliderOptions.max,
            ]

            this.initTypes()
        },
        async save() {
            try {
                this.saveSliderData()
                await userService.createStyles(this.style)
                this.$store.dispatch('loadMe')
                this.$store.dispatch('loadSettings')
                // this.$toast.success(this.$translate('SAVED'))
                this.$stackRouter.pop()
            } catch (e) {
                this.$toast.error('선택지를 모두 입력해주세요')
            }
        },
        saveSliderData() {
            this.style.min_age = this.$refs.ageSlider.myRange[0]
            this.style.max_age = this.$refs.ageSlider.myRange[1]
            this.style.min_height = this.$refs.heightSlider.myRange[0]
            this.style.max_height = this.$refs.heightSlider.myRange[1]
        },
        typeTitle(key) {
            if (!this.me) return

            let value = ''
            if (key === 'region') {
                let regionType
                if (typeof this.style.region_type === 'string') {
                    regionType = JSON.parse(this.style.region_type)
                } else {
                    regionType = this.style.region_type
                }

                ;(regionType || []).forEach(item => {
                    const title = this.$vbConstants.types[key].find(t => t.value === item).title
                    value += `${title}, `
                })
                value = value.slice(0, value.length - 2)
            } else {
                value = (this.$vbConstants.types[key].find(t => t.value === this.style[`${key}_type`]) || {}).title
            }

            if (value) {
                return `<span class="c-primary">${value}</span>`
            } else {
                return `<span class="c-text-lightest">입력하기</span>`
            }
        },
        editType(key) {
            this.$modal
                .custom({
                    component: 'ModalEditStyleType',
                    options: {
                        key,
                    },
                })
                .then(item => {
                    if (!item) return

                    this.disabled[key] = true
                    if (key === 'region') {
                        const arr = []
                        item.forEach(i => arr.push(i.value))
                        this.style[`${key}_type`] = arr
                    } else {
                        this.style[`${key}_type`] = item.value
                    }
                    this.initTypes()
                })
        },
        updateStyleChanged() {
            this.styleChanged = true
        },
        onScroll(event) {
            this.showHeaderTitle = event.target.scrollTop >= 52
        },
    },
}
</script>

<style lang="scss" scoped>
.edit-style {
    $header-height: 52px;
    overflow: hidden;

    .bottom-border {
        border-bottom: 1px solid $grey-02;
    }

    .main {
        height: calc(100vh - #{$header-height});
        padding-bottom: 120px;
        overflow-y: auto;
    }

    .types {
        margin-top: 40px;

        .item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 16px 20px;
        }

        .key {
            @include f-medium;
            font-size: 16px;
            color: black;
        }

        .value {
            @include f-medium;
            font-size: 14px;
        }
    }
    hr {
        margin: 32px 0;
        background: $grey-02;
        height: 1px;
        border-bottom: none;
    }

    ::v-deep .edit-intro {
        textarea {
            height: 200px;
        }
    }
}
</style>
